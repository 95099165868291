import React, { FC } from "react";
import { Card } from "antd";

import { TownItem } from "../types";
import { onlySiteName } from "../utils/normalizers";

interface OwnProps {
  item: TownItem;
}

const TownCard: FC<OwnProps> = ({ item }) => {
  const { name, link } = item;

  return (
    <a href={link}>
      <Card hoverable className="town-card">
        <Card.Meta title={name} description={onlySiteName(link)} />
      </Card>
    </a>
  );
};

export default TownCard;
