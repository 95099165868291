import axios from "axios";

import { TownItem } from "../types";

export interface RegionResponse {
  region: string;
  towns: TownItem[];
}

/* istanbul ignore next */
const api = {
  getRegions: () => axios.get<RegionResponse[]>("./regions.json"),
};

export default api;
