import React, { useState, useEffect } from "react";
import { Layout, Menu, Typography, Row, Col, Image, message } from "antd";

import regionsApi from "./utils/api";
import { Region, RegionTowns } from "./types";
import { TownCard, Footer } from "./components";

import "./App.css";

/* istanbul ignore next */
const imageWidth = window.innerWidth > 450 ? "50%" : "100%";

function App() {
  const [regions, setRegions] = useState<Region[]>([]);
  const [towns, setTowns] = useState<RegionTowns>({});
  const [currentRegion, setCurrentRegion] = useState("");

  useEffect(() => {
    let isActive = true;

    async function getRegions() {
      try {
        const res = await regionsApi.getRegions();

        if (!res.data || !res.data.length)
          throw new Error("Список регионов пуст");

        let normalizedTowns = {};

        res.data.forEach(
          (item) =>
            (normalizedTowns = {
              ...normalizedTowns,
              [item.region]: item.towns,
            })
        );

        /* istanbul ignore else */
        if (isActive) {
          setRegions(
            res.data.map((item) => ({
              name: item.region,
              townsCount: item.towns.length,
            }))
          );
          setTowns(normalizedTowns);
          setCurrentRegion(res.data[0].region);
        }
      } catch (e) {
        if (e instanceof Error) {
          message.error(`Ошибка получения списка регионов: ${e.message}`);
        } else {
          message.error(`Ошибка получения списка регионов`);
        }
      }
    }

    getRegions();

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <Layout className="main-container">
      <Layout.Sider breakpoint="lg" collapsedWidth="0">
        <img src="./logo-white.svg" className="logo" alt="logo" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[currentRegion]}
          onClick={({ key }) => setCurrentRegion(key)}
        >
          {regions &&
            regions.map((item) =>
              // Render menu item only if townsCount > 0
              item.townsCount ? (
                <Menu.Item key={item.name}>{item.name}</Menu.Item>
              ) : null
            )}
          <Menu.Divider />
          <Menu.Item key="Программа лояльности">Программа лояльности</Menu.Item>
        </Menu>
        <img src="./bks.png" className="logo-bks" alt="logo-bks" />
      </Layout.Sider>
      <Layout>
        <Layout.Header className="header">
          <Typography.Title className="title" level={3}>
            Интернет-магазины
          </Typography.Title>
        </Layout.Header>
        <Layout.Content className="content-container">
          {currentRegion === "Программа лояльности" ? (
            <Row>
              <Image.PreviewGroup>
                <Image
                  width={imageWidth}
                  src="./Loyal.jpg"
                  alt="Программа лояльности"
                />
                <Image width={imageWidth} src="./Form.jpg" alt="Анкета" />
              </Image.PreviewGroup>
            </Row>
          ) : (
            <Row gutter={[16, { xs: 8, sm: 16 }]}>
              {towns[currentRegion] &&
                towns[currentRegion].map((item) => (
                  <Col xs={24} sm={12} md={6} key={item.name}>
                    <TownCard item={item} />
                  </Col>
                ))}
            </Row>
          )}
        </Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default App;
