import React from "react";
import { Layout, Typography } from "antd";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <Layout.Footer className="footer">
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        {" "}
        Вычислительный центр Белкоопсоюза ©{currentYear}
      </Typography.Paragraph>
    </Layout.Footer>
  );
};

export default Footer;
